import * as React from "react";
import {
  Paper,
  Typography,
  Button,
  Alert,
  Box,
  TextField,
} from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import ImageIcon from "@mui/icons-material/Image";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SendIcon from "@mui/icons-material/Send";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from '@mui/icons-material/Delete';
import zIndex from "@mui/material/styles/zIndex";

const DashBanner = () => {
  const [banner, setBanner] = useState([{}]);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [validationErrorVisibility, setValidationErrorVisibility] =
    useState("hidden");
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState("");
  useEffect(() => {
    document.title = "AIIPL | Dashboard Banner";
    var url = "https://aiipl.in/app/admin/api/homeBanner.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setBanner(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, [banner]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtension = [".jpg", ".png"];
      const selectedFileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtension.includes("." + selectedFileExtension)) {
        const maxSize = 1024 * 1024 * 2;
        if (file.size < maxSize) {
          setSelectedFile(file);
          //document.getElementById("new_banner_image").src = file.pathname + file.name;
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {file.name} is a valid file!
              </Typography>
            </Alert>
          );
        }
        else{
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                File size must be less than 2MB!
              </Typography>
            </Alert>
          );
        }
      } else {
        setSelectedFile(null);
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Invalid file extension. Please select a file with .jpg or .png
              extension.
            </Typography>
          </Alert>
        );
      }
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const uploadBanner = async (name) => {
    if (selectedFile) {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            File is uploading...
          </Typography>
        </Alert>
      );

      var url = "https://aiipl.in/app/admin/api/changeBannerImageApi.php";
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("name", name);
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Updated successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Please select a file before upload!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const uploadNewBanner = async () => {
    if (selectedFile) {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            File is uploading...
          </Typography>
        </Alert>
      );

      var url = "https://aiipl.in/app/admin/api/addNewBannerImageApi.php";
      const formData = new FormData();
      formData.append("file", selectedFile);
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            document.getElementById("new_banner_image").src = response[0].file;
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Updated successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Please select a file before upload!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const setVal = (e, val) => {
    if (val === "setTitle") {
      setTitle(e);
    } else if (val === "setContent") {
      setContent(e);
    }
  };

  const changeBannerInfo = () => {
    if (title !== "") {
      if (content !== "") {
        setCurrentBanner(currentBanner.toLowerCase());
        var url = "https://aiipl.in/app/admin/api/changeBannerInfoApi.php";
        const formData = new FormData();
        formData.append("name", currentBanner);
        formData.append("title", title);
        formData.append("content", content);
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response[0].result === "success") {
              setValidationError(
                <Alert
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  severity="success"
                >
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    Updated successfully!
                  </Typography>
                </Alert>
              );
              modalClose();
            } else {
              setValidationError(
                <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    {response[0].result}
                  </Typography>
                </Alert>
              );
            }
          })
          .catch((error) => {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {error.message}
                </Typography>
              </Alert>
            );
          });
      } else {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Sub Heading is blank!
            </Typography>
          </Alert>
        );
      }
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Heading is blank!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const addNewBanner = () => {
    if (title !== "") {
      if (content !== "") {
        var url = "https://aiipl.in/app/admin/api/addNewBannerInfoApi.php";
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response[0].result === "success") {
              setValidationError(
                <Alert
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  severity="success"
                >
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    Added successfully!
                  </Typography>
                </Alert>
              );
              modalClose();
            } else {
              setValidationError(
                <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    {response[0].result}
                  </Typography>
                </Alert>
              );
            }
          })
          .catch((error) => {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {error.message}
                </Typography>
              </Alert>
            );
          });
      } else {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Sub Heading is blank!
            </Typography>
          </Alert>
        );
      }
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Heading is blank!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const modalShow = (name, title, content, type) => {
    setModalType(type);
    setCurrentBanner(name.toUpperCase());
    setTitle(title);
    setContent(content);
    setShow(true);
  };
  
  const modalClose = () => {
    if (modalType === "new") {
      setSelectedFile(null);
    }
    setShow(false);
  };

  const removeBanner = (name) => {
    var sure = window.confirm("Are you sure?");
    if(sure){
      setCurrentBanner(currentBanner.toLowerCase());
      var url = "https://aiipl.in/app/admin/api/removeBannerApi.php";
      const formData = new FormData();
      formData.append("name", name);
      fetch(url, {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      .then((response) => {
        if (response[0].result === "success") {
          setValidationError(
            <Alert
              sx={{ marginTop: 2, marginBottom: 2 }}
              severity="success"
            >
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Removed successfully!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {response[0].result}
              </Typography>
            </Alert>
          );
        }
      })
      .catch((error) => {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {error.message}
            </Typography>
          </Alert>
        );
      });
    }
    else{
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Task aborted!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          marginTop: "10rem",
          marginBottom: "10rem",
          zIndex: "9999",
        }}
      >
        <div>
          {modalType === "new" ? (
            <Modal
              show={show}
              style={{ marginTop: "80px", zIndex: 9999 }}
              size="lg"
            >
              <Modal.Header
                className="text-white"
                style={{ background: "#00004d" }}
              >
                <Modal.Title style={{ fontSize: "20px" }}>
                  Add New Banner
                </Modal.Title>
                <CloseButton variant="white" onClick={modalClose} />
              </Modal.Header>

              <Modal.Body style={{ background: "transparent" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: ".5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "17rem",
                        height: "17rem",
                        boxShadow: "1px 2px 3px rgba(0,0,0,.4)",
                        borderRadius: "5px",
                        border: "1px solid rgba(0,0,0,.25)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        id="new_banner_image"
                        src="https://cdn.iconscout.com/icon/free/png-256/free-upload-675-475051.png"
                        alt="demo"
                        style={{
                          width: "15rem",
                          height: "15rem",
                          border: "1px solid rgba(0,0,0,.25)",
                          borderRadius: "5px",
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="file"
                      id="new_banner"
                      style={{
                        marginBottom: "1rem",
                      }}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Ubuntu",
                        marginRight: 1,
                        backgroundColor: "#00004d",
                      }}
                      onClick={uploadNewBanner}
                    >
                      Add Image
                      <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                    </Button>
                    <TextField
                      id="title"
                      label="Heading"
                      value={title}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: 300,
                        marginTop: 2,
                      }}
                      onChange={(e) => setVal(e.target.value, "setTitle")}
                    />
                    <TextField
                      id="content"
                      label="Sub Heading"
                      value={content}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: 300,
                        marginTop: 2,
                      }}
                      onChange={(e) => setVal(e.target.value, "setContent")}
                    />
                  </Box>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                  onClick={addNewBanner}
                >
                  Submit
                  <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
                <Button
                  onClick={modalClose}
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                >
                  Close
                  <CancelPresentationIcon
                    sx={{ marginLeft: 1, fontSize: 20 }}
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            <Modal show={show} style={{ marginTop: "80px", zIndex: 9999 }}>
              <Modal.Header
                className="text-white"
                style={{ background: "#00004d" }}
              >
                <Modal.Title style={{ fontSize: "20px" }}>
                  Change Banner Info [ {currentBanner} ]
                </Modal.Title>
                <CloseButton variant="white" onClick={modalClose} />
              </Modal.Header>

              <Modal.Body style={{ background: "transparent" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    id="title"
                    label="Heading"
                    value={title}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      width: 300,
                      marginTop: 2,
                    }}
                    onChange={(e) => setVal(e.target.value, "setTitle")}
                  />
                  <TextField
                    id="content"
                    label="Sub Heading"
                    value={content}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      width: 300,
                      marginTop: 2,
                    }}
                    onChange={(e) => setVal(e.target.value, "setContent")}
                  />
                </Box>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                  onClick={changeBannerInfo}
                >
                  Submit
                  <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
                <Button
                  onClick={modalClose}
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                >
                  Close
                  <CancelPresentationIcon
                    sx={{ marginLeft: 1, fontSize: 20 }}
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        <div
          style={{
            zIndex: "999999",
            position: "fixed",
            top: "8.5rem",
            visibility: validationErrorVisibility,
            zIndex: '999'
          }}
        >
          {validationError}
        </div>
        <div style={{ zIndex: '999' }}>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={() => modalShow("", "", "", "new")}
          >
            New Banner
            <AddBoxIcon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </div>
        {banner.map((ban) => (
          <Paper
            key={ban.name}
            sx={{
              width: 900,
              height: 350,
              padding: 5,
              boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
              background: "transparent",
              zIndex: 999,
            }}
          >
            <div
              style={{
                padding: "15px",
                background: "rgba(255,255,255,.25)",
                borderRadius: "5px",
                boxShadow: "2px 3px 5px rgba(0,0,0,.4)",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <img
                  src={ban.url}
                  style={{
                    width: "225px",
                    height: "225px",
                    borderRadius: "5px",
                  }}
                  alt={ban.name}
                />
              </div>
              <div
                style={{
                  width: "350px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    textTransform: "uppercase",
                  }}
                >
                  {ban.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 15,
                    fontWeight: 900,
                  }}
                >
                  Heading: {ban.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 14,
                    fontWeight: 900,
                  }}
                >
                  Sub Heading: {ban.content}
                </Typography>
                <div>
                  <input
                    type="file"
                    id={ban.name}
                    style={{
                      marginBottom: "1rem",
                    }}
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                    onClick={() => uploadBanner(ban.name)}
                  >
                    Change Image
                    <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                    onClick={() =>
                      modalShow(ban.name, ban.title, ban.content, "change")
                    }
                  >
                    Edit Info
                    <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#262626",
                    }}
                    onClick={() => removeBanner(ban.name)}
                  >
                    Remove Banner
                    <DeleteIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
              </div>
            </div>
          </Paper>
        ))}
      </div>
    </>
  );
};

export default DashBanner;
