import { Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CallIcon from "@mui/icons-material/Call";
import { useEffect, useState } from "react";
import "../Style.css";
import Footer from "./Footer";

function Client() {
  const [clients, setClients] = useState([{}]);
  useEffect(() => {
    document.title = "AIIPL | Client";
    var url = "https://aiipl.in/app/admin/api/clientData.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setClients(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };

  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>OUR CLIENTS</h1>
          </div>
        </div>
      </div>
      <div className="about_div" style={{ height: 'auto' }}>
        <div style={{ marginTop: "3rem", marginLeft: "3rem" }}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 5,
              }}
            >
              {clients.map((client, index) => (
                <div key={index} style={{
                  padding: '1rem',
                  background: 'rgba(255,255,255,.25)',
                  borderRadius: '5px',
                }}>
                  <img
                    className="client_img"
                    src={client.img}
                    alt={client.label}
                  />
                </div>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
      <div className="request_call">
        <p className="quote">Want to request a quote now !</p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Client;
