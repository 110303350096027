import * as React from "react";
import { Typography, TextField, Button, Alert } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import SendIcon from "@mui/icons-material/Send";
import BusinessIcon from "@mui/icons-material/Business";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { useEffect, useState } from "react";
import Footer from "./Footer";

const Contact = () => {
  const [cInfo, setCInfo] = useState([{}]);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [validationError, setValidationError] = useState("");
  const [visibility, setVisibility] = useState("hidden");

  useEffect(() => {
    document.title = "AIIPL | Contact";
    var url = "https://aiipl.in/app/admin/api/contactInfo.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setCInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
    //eslint-disable-next-line
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };

  const setVal = (e, val) => {
    if (val === "setFName") {
      setFName(e);
    } else if (val === "setLName") {
      setLName(e);
    } else if (val === "setMobile") {
      setMobile(e);
    } else if (val === "setEmail") {
      setEmail(e);
    } else if (val === "setMsg") {
      setMsg(e);
    }
  };

  const handleSubmit = async () => {
    if (fname === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            First name is blank!
          </Typography>
        </Alert>
      );
    } else if (lname === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Last name is blank!
          </Typography>
        </Alert>
      );
    } else if (mobile === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Mobile is blank!
          </Typography>
        </Alert>
      );
    } else if (email === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Email is blank!
          </Typography>
        </Alert>
      );
    } else if (msg === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Message is blank!
          </Typography>
        </Alert>
      );
    } else {
      var url = "https://aiipl.in/app/admin/api/newContact.php";
      const formData = new FormData();
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("mob", mobile);
      formData.append("email", email);
      formData.append("msg", msg);
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Message has sent successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    }
    setVisibility("visible");
    setTimeout(function () {
      setVisibility("hidden");
    }, 5000);
  };

  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>CONTACT US</h1>
          </div>
        </div>
      </div>
      <div className="career_div">
        <div
          style={{
            zIndex: "999999",
            position: "fixed",
            top: "8.5rem",
            visibility: visibility,
            zIndex: "999",
          }}
        >
          {validationError}
        </div>
        <div className="career_content_div">
          <div className="career_content">
            <TextField
              id="fname"
              label="First Name"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setFName")}
            />
            <TextField
              id="lname"
              label="Last Name"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setLName")}
            />
            <TextField
              id="mobile"
              label="Mobile"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setMobile")}
            />
          </div>
          <div className="career_content">
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setEmail")}
            />
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea2"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Message"
                  onChange={(e) => setVal(e.target.value, "setMsg")}
                  style={{
                    width: "250px",
                    background: "transparent",
                    border: "1px solid rgba(0,0,0,.25)",
                  }}
                />
              </Form.Group>
            </Form>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                backgroundColor: "#00004d",
                marginRight: 0.5,
              }}
              onClick={handleSubmit}
            >
              <SendIcon sx={{ marginRight: 1, fontSize: 20 }} /> SUBMIT
            </Button>
          </div>
        </div>
      </div>
      <div className="home_strength">
        <div
          style={{
            marginTop: "3rem",
            width: '100vw',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
              marginBottom: 2
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 5,
              }}
            >
              {cInfo.map((info, index) => (
                <>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.5)",
                      padding: "1rem",
                      width: "15rem",
                      height: "13rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "1rem"
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 22,
                        textAlign: "center",
                      }}
                    >
                      <BusinessIcon sx={{ marginLeft: 1.5, fontSize: 35 }} />
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      {info.addr}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.5)",
                      padding: "1rem",
                      width: "15rem",
                      height: "13rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "1rem"
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 22,
                        textAlign: "center",
                      }}
                    >
                      <ContactPhoneIcon sx={{ marginLeft: 1.5, fontSize: 35 }} />
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      {info.mob}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.5)",
                      padding: "1rem",
                      width: "15rem",
                      height: "13rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "1rem"
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 22,
                        textAlign: "center",
                      }}
                    >
                      <EmailIcon sx={{ marginLeft: 1.5, fontSize: 35 }} />
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      {info.ema}
                    </Typography>
                  </Paper>
                </>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14732.317955154906!2d88.45587667511744!3d22.613506679572122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPS%20ABACUS%2C%20Unit%20No.%20532%2C%20Newtwon%2C%20Kolkata%20-%20700157!5e0!3m2!1sen!2sin!4v1719182034899!5m2!1sen!2sin" width="600" height="450" style={{ maxWidth: '100vw', width: '100vw', border:'0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div className="request_call">
        <p className="quote">
          Want to request a quote now !
        </p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
