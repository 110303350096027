import * as React from "react";
import {
  Alert,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import SendIcon from '@mui/icons-material/Send';
import UpdateIcon from "@mui/icons-material/Update";
import ImageIcon from "@mui/icons-material/Image";
import { Row, Col, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Style.css";

const DashHome = () => {
  const navigate = useNavigate();
  const [sInfo, setSInfo] = useState("");
  const [trackError, setTrackError] = useState("");
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState('');
  function getSiteInfo() {
    var url = "https://aiipl.in/app/admin/api/siteInfo.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setSInfo(response[0].title + "," + response[0].content);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }
  function setSiteInfo() {
    var url = "https://aiipl.in/app/admin/api/updateSiteInfo.php";
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    var Data = {
      sinfo: sInfo,
    };
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(Data),
    })
      .then((response) => response.json())
      .then((response) => {
        setTrackError(
          <Alert sx={{ marginBottom: 2 }} severity="success">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {response[0].result}
            </Typography>
          </Alert>
        );
      })
      .catch((error) => {
        setTrackError(
          <Alert sx={{ marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Error: {error}
            </Typography>
          </Alert>
        );
      });
  }
  const modalShow = (type) => {
    setModalType(type);
    setShow(true);
    setTrackError('');
}
const modalClose = () => {
  setSInfo('');
  setShow(false);
}
  useEffect(() => {
    document.title = "AIIPL | Dashboard Home";
    getSiteInfo();
  }, [sInfo]);
  function updateSiteInfo() {
    modalClose();
    if (sInfo === "") {
      setTrackError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Site info has left blank!
          </Typography>
        </Alert>
      );
    } else {
      setSiteInfo();
    }

    setTimeout(function () {
      setTrackError("");
    }, 5000);
  }

  function openBanners(){
    navigate("/dashboard/banner");
  }
  return (
    <>
      <Container className="center" style={{ zIndex: '99' }}>
          <Modal show={show} style={{ marginTop: '80px', zIndex: '9999' }}>
                    <Modal.Header className="text-white" style={{ background: '#00004d' }}>
                        <Modal.Title style={{ fontSize: "20px" }}>{modalType}</Modal.Title>
                        <CloseButton variant="white" onClick={modalClose} />
                    </Modal.Header>

                    <Modal.Body>
                    <TextField
                    id="siteinfo"
                    label="Site Info"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginBottom: 1,
                      width: 450,
                      
                    }}
                    onChange={(e) => setSInfo(e.target.value)}
                  />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={updateSiteInfo} variant='contained' sx={{ fontFamily: 'Ubuntu', marginRight: 1, backgroundColor: '#00004d' }}>
                            Submit
                            <SendIcon
                                sx={{ marginLeft: 1, fontSize: 20 }} 
                            />
                        </Button>
                        <Button onClick={modalClose} variant='contained' sx={{ fontFamily: 'Ubuntu', marginRight: 1, backgroundColor: '#00004d' }}>
                            Close
                            <CancelPresentationIcon
                                sx={{ marginLeft: 1, fontSize: 20 }} 
                            />
                        </Button>
                    </Modal.Footer>
                </Modal>
        {trackError}
        <Row>
          <Col>
            <Paper
              className="contentBox"
              sx={{
                padding: 10,
                boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
                background: "transparent",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontFamily: 'Ubuntu' }}>Change Site Info</Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 17,
                      fontWeight: 900,
                      marginBottom: 1,
                      width: 300,
                      height: 50,
                      
                    }}
                  >
                    {sInfo}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => modalShow('Site Info')}
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                  >
                    Edit
                    <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                  <Button
                    onClick={openBanners}
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                  >
                    Change Banners
                    <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Col>
          <Col>
            <Paper
              className="contentBox"
              sx={{
                padding: 10,
                boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
                background: "rgba(255,255,255,.2)",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1.65 }}>
                <Box>
                  <Typography variant="h6">Change Site Images</Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                  >
                    Change Site Icon
                    <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                  >
                    Change Site Logo
                    <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Col>
        </Row>
      </Container>
      <div style={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100vw',
      height: '200vh',
      background: 'rgba(2,0,36)',
      background: 'linear-gradient(90deg, rgba(106, 169, 121) 0%, rgba(76, 76, 199) 35%, rgba(0,212,255,1) 100%)',
      zIndex: '-1'
    }}></div>
    </>
  );
};

export default DashHome;
