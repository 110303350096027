import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { useEffect } from 'react';

const TermsOfUse = () => {
  useEffect(() => {
    document.title = "AIIPL | Terms Of Use";  
  }, []);

  return (
    <>
        <Paper 
            sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%',
                transform: 'translate(-50%,-50%)',
                padding: 10,
                boxShadow: 'rgba(0,0,0,.35) 2px 2px 10px',
                background: 'transparent'
            }}
        >
            <Typography variant='h3'>TermsOfUse Page</Typography>
        </Paper>
    </>
  )
}

export default TermsOfUse