import { Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useEffect, useState } from "react";
import "../Style.css";

function HomeClient() {
  const [clients, setClients] = useState([{}]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/clientData.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setClients(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }, []);
  return (
    <>
      <div className="home_client">
        <div
          style={{
            position: "absolute",
            left: "50%",
            translate: "-50%",
          }}
        >
          <div className="section-title">
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Our Clients
              <BusinessCenterIcon sx={{ marginLeft: 1.5, fontSize: 20 }} />
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: "-50px" }}>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            arrows={false}
            swipeable={true}
          >
            {clients.map((client, index) => (
              <div key={index} className="cl_im">
                <img
                  className="client-image"
                  src={client.img}
                  alt={client.name}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default HomeClient;
