import * as React from "react";
import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../Style.css";
import HomeTeam from "./HomeTeam";
import Footer from "./Footer";

const About = () => {
  const [about, setAbout] = useState([{}]);
  useEffect(() => {
    document.title = "AIIPL | About";
    var url = "https://aiipl.in/app/admin/api/about.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        console.log(about);
        setAbout(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>ABOUT US</h1>
          </div>
        </div>
      </div>
      <div className="about_div">
        <div className="about_content">
          <Paper
            style={{
              background: "rgba(255,255,255,.5)",
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            elevation={10}
          >
            <Typography
              style={{
                fontFamily: "Ubuntu",
                fontSize: "15px",
                textAlign: "justify",
              }}
            >
              {about[0].para1}
            </Typography>
          </Paper>
        </div>
        <div className="about_content">
        <Paper
            style={{
              background: "rgba(255,255,255,.5)",
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            elevation={10}
          >
          <Typography
            style={{
              fontFamily: "Ubuntu",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            {about[0].para2}
          </Typography>
          </Paper>
        </div>
        <div className="about_content">
        <Paper
            style={{
              background: "rgba(255,255,255,.5)",
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            elevation={10}
          >
          <Typography
            style={{
              fontFamily: "Ubuntu",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            {about[0].para3}
          </Typography>
          </Paper>
        </div>
      </div>
      <div
        style={{
          marginTop: "-3rem",
        }}
      >
        <HomeTeam />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default About;
