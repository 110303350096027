import { Typography } from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useEffect, useState } from "react";
import "../Style.css";

function Testimonial() {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [tm, setTM] = useState([{}]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const modalShow = (img) => {
    setImage(img);
    setShow(true);
  };

  const modalClose = () => {
    setImage("");
    setShow(false);
  };

  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/testimonial.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setTM(response);
      })
      .catch((error) => {
        alert("Error: " + error);
        console.log(error);
      });
  }, []);
  return (
    <>
      
        <Modal
          show={show}
          style={{ maxWidth: "100vw", zIndex: '99999', marginTop: '0'}}
          size="lg"
        >
          <Modal.Header>
          <CloseButton variant="#00004d" onClick={modalClose} />
          </Modal.Header>
          <Modal.Body>
            <img style={{ width: '100%', height: '120vh', border: '1px solid rgba(0,0,0,.5)' }} src={image} alt="" />
          </Modal.Body>
        </Modal>

      <div
        style={{
          position: "relative",
          paddingTop: "50px",
          width: "100vw",
          height: "auto",
          background: "lightslategray",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            translate: "-50%",
          }}
        >
          <div className="section-title">
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Awards & Recognition
              <BusinessCenterIcon sx={{ marginLeft: 1.5, fontSize: 20 }} />
            </Typography>
          </div>
        </div>
        <div style={{ padding: "25px", marginTop: "5rem" }}>
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            arrows={false}
            swipeable={true}
          >
          {tm.map((tms, index) => (
              <div key={index} className="tes_tes">
                <Paper
                  style={{
                    background: "rgba(255,255,255,.5)",
                    padding: "1rem",
                    width: "18rem",
                    height: "21rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer"
                  }}
                  elevation={10}
                >
                  <img className="tes-image" src={tms.url} alt={tms.name} onClick={() => modalShow(tms.url)} />
                </Paper>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
