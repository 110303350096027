import { Typography, Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Footer() {
  const [siteInfo, setSiteInfo] = useState([{}]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const downloadFromPlaystore = (url) => {
    window.location.href = url;
  };

  const openFacebook = () => {
    window.location.href = "https://facebook.com";
  };

  const openInstagram = () => {
    window.location.href = "https://instagram.com";
  };

  const openX = () => {
    window.location.href = "https://x.com";
  };

  const openIn = () => {
    window.location.href = "https://linkedin.com";
  };

  const setVal = (e, val) => {
    if (val === "setName") {
      setName(e);
    } else if (val === "setEmail") {
      setEmail(e);
    } else if (val === "setMessage") {
      setMessage(e);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      //loginSubmit();
    }
  };

  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/siteInfo.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setSiteInfo(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="footer_main">
        <div className="footer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "1.5rem",
            }}
          >
            <div>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 20,
                  fontWeight: 900,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                ABOUT US
              </Typography>
            </div>
            <div>
              {siteInfo.map((info, index) => (
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 15,
                    textAlign: "justify",
                    width: "150px",
                    height: "50px",
                    color: "#fff",
                  }}
                >
                  {info.sabout}
                </Typography>
              ))}
              <NavLink
                to="/about"
                style={{
                  textDecoration: "none",
                  color: "yellow",
                  position: "relative",
                  top: "1rem",
                  fontSize: ".85rem"
                }}
              >
                Read more...
              </NavLink>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "1.5rem",
            }}
          >
            <div>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 20,
                  fontWeight: 900,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                QUICK LINKS
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "150px",
                height: "75px",
              }}
            >
              <NavLink
                to="/gallery"
                style={{ textDecoration: "none", color: "yellow" }}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/contact"
                style={{ textDecoration: "none", color: "yellow" }}
              >
                Contact
              </NavLink>
              <NavLink
                to="/privacypolicy"
                style={{ textDecoration: "none", color: "yellow" }}
              >
                Privacy Policy
              </NavLink>
              <NavLink
                to="/termsofuse"
                style={{ textDecoration: "none", color: "yellow" }}
              >
                Terms Of Use
              </NavLink>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "1.5rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 20,
                fontWeight: 900,
                textAlign: "center",
                color: "#fff",
              }}
            >
              GO SOCIAL
            </Typography>
            <div
              style={{
                height: "35px",
              }}
            >
              <span onClick={openFacebook}>
                <FacebookIcon
                  sx={{
                    marginRight: 0.5,
                    fontSize: 35,
                    color: "#1877F2",
                    cursor: "pointer",
                    background: "rgba(255,255,255,.35)",
                    width: "24px",
                    height: "24px"
                  }}
                />
              </span>
              <span onClick={openInstagram}>
                <InstagramIcon
                  sx={{
                    marginRight: 0.5,
                    fontSize: 35,
                    color: "#E4405F",
                    cursor: "pointer",
                    background: "rgba(255,255,255,.35)",
                    width: "24px",
                    height: "24px"
                  }}
                />
              </span>
              <span onClick={openX}>
                <XIcon
                  sx={{
                    marginRight: 0.5,
                    fontSize: 30,
                    color: "#000",
                    cursor: "pointer",
                    background: "rgba(255,255,255,.35)",
                    width: "24px",
                    height: "24px"
                  }}
                />
              </span>
              <span onClick={openIn}>
                <LinkedInIcon
                  sx={{
                    marginRight: 0.5,
                    fontSize: 35,
                    color: "#0A66C2",
                    cursor: "pointer",
                    background: "rgba(255,255,255,.35)",
                    width: "24px",
                    height: "24px"
                  }}
                />
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "1.5rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 20,
                fontWeight: 900,
                textAlign: "center",
                color: "#fff",
              }}
            >
              CONTACT US
            </Typography>
            <div
              style={{
                height: "12rem",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                background: "rgba(255,255,255,.55)",
                boxShadow: "2px 2px 2px 2px rgba(0,0,0,.25)",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <TextField
                id="name"
                variant="standard"
                placeholder="Name"
                autoComplete="off"
                sx={{
                  width: 200,
                  fontFamily: "Ubuntu",
                  marginBottom: 0.1,
                }}
                onChange={(e) => setVal(e.target.value, "setName")}
                onKeyDown={onKeyDownHandler}
              />
              <TextField
                id="email"
                variant="standard"
                placeholder="Email"
                autoComplete="off"
                sx={{
                  width: 200,
                  height: 20,
                  fontFamily: "Ubuntu",
                  marginBottom: 1.5,
                }}
                onChange={(e) => setVal(e.target.value, "setEmail")}
                onKeyDown={onKeyDownHandler}
              />
              <TextField
                id="message"
                variant="standard"
                placeholder="Message"
                autoComplete="off"
                sx={{
                  width: 200,
                  height: 20,
                  fontFamily: "Ubuntu",
                  marginBottom: 3,
                }}
                onChange={(e) => setVal(e.target.value, "setMessage")}
                onKeyDown={onKeyDownHandler}
              />
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  backgroundColor: "#00004d",
                }}
              >
                <SendIcon
                  sx={{
                    marginRight: 0.5,
                    fontSize: 15,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />{" "}
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            marginTop: "2rem",
            width: "100vw",
            height: "50px",
            background: "rgba(0,0,0,.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 14,
              fontWeight: 400,
              textAlign: "center",
              color: "#000",
            }}
          >
            Copyright © 2025 | All rights reserved
          </Typography>
        </div>
      </div>
      <div className="chat_div">
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 15,
            marginRight: 1,
            backgroundColor: "#34a853",
            zIndex: "9999",
          }}
          onClick={() => downloadFromPlaystore("https://wa.me/919903985877")}
        >
          <WhatsAppIcon sx={{ marginRight: 1, fontSize: 20 }} />
          CHAT ON WHATSAPP
        </Button>
      </div>
    </>
  );
}

export default Footer;
