import { Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Modal, CloseButton } from "react-bootstrap";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from '@mui/icons-material/Person';
import { useEffect, useState } from "react";
import "../Style.css";

function HomeTeam() {
  const [member, setMember] = useState([{}]);
  const [show, setShow] = useState(false);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [mdes, setMDes] = useState("");

  const modalShow = (fname, lname, des) => {
    setFName(fname);
    setLName(lname);
    setMDes(des);
    setShow(true);
  };

  const modalClose = () => {
    setFName("");
    setLName("");
    setMDes("");
    setShow(false);
  };

  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/team.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setMember(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };

  return (
    <>
     <Modal
          show={show}
          style={{ maxWidth: "100vw", zIndex: '99999', marginTop: '7.5rem'}}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>{fname} {lname}</Modal.Title>
            <CloseButton variant="#00004d" onClick={modalClose} />
          </Modal.Header>
          <Modal.Body>
          <Paper
            style={{
              background: "rgba(255,255,255,.25)",
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            elevation={10}
          >
          <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 14,
                textAlign: "justify",
              }}
          >
            {mdes}
          </Typography>
          </Paper>
          </Modal.Body>
        </Modal>
      <div className="home_team">
        <div
          style={{
            position: "absolute",
            left: "50%",
            translate: "-50%",
          }}
        >
          <div className="section-title">
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Our Team
              <SettingsApplicationsIcon
                sx={{ marginLeft: 1.5, fontSize: 20 }}
              />
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: "8rem", marginLeft: "3rem" }}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 10,
              }}
            >
              {member.map((memb, index) => (
                <Paper
                  style={{
                    background: "rgba(255,255,255,.5)",
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                  }}
                  elevation={10}
                >
                  <img
                    src={memb.img}
                    alt={memb.fname}
                    style={{
                      width: "225px",
                      height: "200px",
                      border: "1px solid rgba(0,0,0,.25)",
                      borderRadius: "5px",
                      boxShadow: "1px 1px 1px rgba(0,0,0,.25)",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: ".5rem",
                      marginBottom: ".1rem",
                    }}
                  >
                    {memb.fname} {memb.lname}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 900,
                      textAlign: "center",
                    }}
                  >
                    {memb.desig}
                  </Typography>
                  <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 10,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => modalShow(memb.fname, memb.lname, memb.descr)}
        >
          <PersonIcon sx={{ marginRight: 1, fontSize: 12 }} />
          KNOW MORE
        </Button>
                </Paper>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
      <div className="request_call">
        <p className="quote">
          Want to request a quote now !
        </p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
    </>
  );
}

export default HomeTeam;
