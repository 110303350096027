import { Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { useEffect, useState } from "react";
import "../Style.css";

function HomeStrength() {
  const [strengths, setStrengths] = useState([{}]);
  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/milestone.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setStrengths(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }, []);
  return (
    <>
      <div className="home_strength">
        <div
          style={{
            position: "absolute",
            left: "50%",
            translate: "-50%",
            textAlign: 'center'
          }}
        >
          <div className="section-title">
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Our Strength
              <CenterFocusStrongIcon sx={{ marginLeft: 1.5, fontSize: 20 }} />
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginTop: "8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 999
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr 1fr" },
                gap: 5,
              }}
            >
              {strengths.map((strength, index) => (
                <>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopRightRadius: "75px",
                      borderBottomLeftRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile1}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopRightRadius: "75px",
                      borderBottomLeftRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile2}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile3}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile4}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile5}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile6}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile7}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      background: "rgba(255,255,255,.35)",
                      borderRadius: "5px",
                      backdropFilter: 'blur(5px)',
                      borderTopLeftRadius: "75px",
                      borderBottomRightRadius: "75px",
                      boxShadow: '2px 3px 4px rgba(0,0,0,.25)',
                      border: '1px solid rgba(0,0,0,.35)',
                      padding: "1rem",
                      width: "11.5rem",
                      height: "11.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                    elevation={10}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 13,
                        fontWeight: 900,
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: '#000',
                      }}
                    >
                      {strength.mile8}
                    </Typography>
                  </Paper>
                </>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default HomeStrength;
