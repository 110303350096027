import * as React from 'react';
import { useState, useEffect } from 'react';
import { AppBar, Toolbar, Container, Typography, Button, Box, IconButton } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CottageIcon from '@mui/icons-material/Cottage';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { NavLink, Link, Outlet } from 'react-router-dom';
import '../Style.css';

function Layout() {
  const [siteInfo, setSiteInfo] = useState([{}]);
  const [diviData, setDiviData] = useState([{}]);
  const [show, setShow] = useState('viewD');
  const [showDivi, setShowDivi] = useState('0');
  const [showDiviCon, setShowDiviCon] = useState('hidden');
  const handleShow = () => {
    setShow(show === 'viewD' ? 'viewM' : 'viewD');
  }
  const showDivision = () => {
    setShowDivi(showDivi === '0' ? '100vw' : '0');
    setShowDiviCon(showDiviCon === 'hidden' ? 'visible' : 'hidden');
  }
  useEffect(() => {
    var url = 'https://aiipl.in/app/admin/api/siteInfo.php';
    var durl = 'https://aiipl.in/app/admin/api/divisionData.php';
    fetch(url).then((response) => response.json()).then((response) => 
        {
            setSiteInfo(response);
        }
    ).catch((error) =>
        {
            alert('Error: ' + error);
        }
    );
    fetch(durl).then((response) => response.json()).then((response) => 
      {
          setDiviData(response);
      }
  ).catch((error) =>
      {
          alert('Error: ' + error);
      }
  );

  }, []);

  return (
    <>
      <AppBar 
        sx={{ zIndex: 99999, height: 80, display: 'flex', justifyContent: 'center', background: 'rgba(255,255,255,.5)' }}
        position='fixed'
        className='AppBar'
      >
        <Container maxWidth='l'>
          <Toolbar disableGutters>
            <img src='https://aiipl.in/images/logo/logo.png' alt='Logo' className='nav-logo' />
            <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 15 }}>
            {
              siteInfo.map((info, index) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
                  <h1 className='nav-title'
                  >
                    {info.title}
                  </h1>
                  <Typography
                    variant='h6' 
                    style={{ color: '#000' }}
                    sx={{ fontFamily: 'Ubuntu', fontSize: 12, marginTop: '-8px' }}
                  >
                    {info.content}
                  </Typography>
                </Box>
              ))
            }
              <Box className='resp' onClick={handleShow}>
                <IconButton color='inherit'>
                  <DehazeIcon />
                </IconButton>
              </Box>
              <Box className={show} style={{ marginTop: '.5rem' }}>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/' 
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? ' #00004d' : '#000'}}}
                >
                  <CottageIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  />Home
                </Button>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/about' 
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? '#00004d' : '#000'}}}
                >
                  <ArticleIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  /> About
                </Button>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/service' 
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? '#00004d' : '#000'}}}
                >
                  <SettingsIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  />Service
                </Button>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/division'
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? '#00004d' : '#000'}}}
                >
                  <CollectionsBookmarkIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  />Division 
                </Button>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/career' 
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? '#00004d' : '#000'}}}
                >
                  <SchoolIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  />Career
                </Button>
                <Button 
                  className='button'
                  onClick={handleShow} 
                  component={NavLink} 
                  to='/client' 
                  color='inherit' 
                  sx={{ fontWeight: 100, fontSize: 12, marginRight: 2, fontFamily: 'Ubuntu' }}
                  style={({isActive}) => {return {color: isActive ? '#00004d' : '#000'}}}
                >
                  <BusinessCenterIcon 
                    sx={{ marginRight: .5, fontSize: 18 }} 
                  />Client
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div
        style={{
          position: 'fixed',
          top: '7.5rem',
          left: '0',
          width: showDivi,
          height: '50vh',
          background: 'rgba(255,255,255,.6)',
          boxShadow: '1px 2px 3px rgba(0,0,0,.7)',
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '999999'
        }}
        onMouseOut={showDivision}
      >
        {
              diviData.map((divis, index) => (
                <div key={index} style={{
                  visibility: showDiviCon,
                  width: '225px',
                  height: '225px',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'rgba(255,255,255,1)',
                  padding: '15px',
                  borderRadius: '5px',
                  boxShadow: '1px 2px 3px rgba(0,0,0,.7)'
                }}>
                  <Link to={{ pathname: divis.site }} target='_blank' >
                  <img src={divis.img} style={{ 
                    cursor: 'pointer', 
                    borderRadius: '5px', 
                    width: '195px', 
                    height: '195px', 
                    border: '1px solid rgba(0,0,0,.1)',
                    boxShadow: '1px 2px 3px rgba(0,0,0,.2)',
                    visibility: showDiviCon 
                  }} />
                  </Link>
                </div>
              ))
        }
      </div>
      <Outlet />
    </>
  );
}

export default Layout;
