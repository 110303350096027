import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Button, Alert } from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SendIcon from "@mui/icons-material/Send";
import Diversity3Icon from '@mui/icons-material/Diversity3';

const DashAbout = () => {
  const navigate = useNavigate();
  const [about, setAbout] = useState([{}]);
  const [validationError, setValidationError] = useState(null);
  const [paraName, setParaName] = useState(null);
  const [paraValue, setParaValue] = useState(null);
  const [validationErrorVisibility, setValidationErrorVisibility] =
    useState("hidden");
  const [show, setShow] = useState(false);

  const setVal = (e) => {
    if (e !== "" || e !== null) {
      setParaValue(e);
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            {paraName} is blank!
          </Typography>
        </Alert>
      );
      setValidationErrorVisibility("visible");
      setTimeout(function () {
        setValidationErrorVisibility("hidden");
      }, 5000);
    }
  };

  const updateAbout = () => {
    var url = "https://aiipl.in/app/admin/api/about.php";
    const formData = new FormData();
    formData.append("name", paraName);
    formData.append("value", paraValue);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response[0].result === "success") {
          modalClose();
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Updated successfully!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {response[0].result}
              </Typography>
            </Alert>
          );
        }
      })
      .catch((error) => {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {error.message}
            </Typography>
          </Alert>
        );
      });
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  useEffect(() => {
    document.title = "AIIPL | Dashboard About";
    var url = "https://aiipl.in/app/admin/api/about.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        console.log(about);
        setAbout(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, [about]);

  const modalShow = (name, value) => {
    setParaName(name);
    setParaValue(value);
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const openTeam = () => {
    navigate("/dashboard/team");
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          style={{ marginTop: "80px", zIndex: 9999 }}
          size="lg"
        >
          <Modal.Header
            className="text-white"
            style={{ background: "#00004d" }}
          >
            <Modal.Title style={{ fontSize: "20px" }}>{paraName}</Modal.Title>
            <CloseButton variant="white" onClick={modalClose} />
          </Modal.Header>

          <Modal.Body style={{ background: "transparent" }}>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={5}
                  defaultValue={paraValue}
                  onChange={(e) => setVal(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                marginRight: 1,
                backgroundColor: "#00004d",
              }}
              onClick={updateAbout}
            >
              Submit
              <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
            </Button>
            <Button
              onClick={modalClose}
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                marginRight: 1,
                backgroundColor: "#00004d",
              }}
            >
              Close
              <CancelPresentationIcon sx={{ marginLeft: 1, fontSize: 20 }} />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        style={{
          zIndex: "999999",
          position: "fixed",
          top: "6.5rem",
          left: '35rem',
          visibility: validationErrorVisibility,
        }}
      >
        {validationError}
      </div>
      <div style={{
        position: 'relative',
        top: '10rem',
        left: '38rem',
        marginBottom: '12rem'
      }}>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={openTeam}
          >
            Change Team
            <Diversity3Icon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          margin: "3rem",
          marginTop: "10rem",
          marginBottom: "10rem",
          background: "rgba(255,255,255,.25)",
          padding: "2.5rem",
          backdropFilter: "blur(5px)",
        }}
      >
        <Paper
          sx={{
            padding: 2.5,
            width: 400,
            height: 300,
            boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            zIndex: "99999",
          }}
        >
          <Typography
            style={{
              fontFamily: "Ubuntu",
              fontSize: "14px",
            }}
          >
            {about[0].para1}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={() => modalShow("Paragraph 1", about[0].para1, )}
          >
            Edit
            <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </Paper>
        <Paper
          sx={{
            padding: 2.5,
            width: 400,
            height: 300,
            boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            zIndex: "99999",
          }}
        >
          <Typography
            style={{
              fontFamily: "Ubuntu",
              fontSize: "14px",
            }}
          >
            {about[0].para2}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={() => modalShow("Paragraph 2", about[0].para2)}
          >
            Edit
            <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </Paper>
        <Paper
          sx={{
            padding: 2.5,
            width: 400,
            height: 300,
            boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            zIndex: "99999",
          }}
        >
          <Typography
            style={{
              fontFamily: "Ubuntu",
              fontSize: "12.5px",
            }}
          >
            {about[0].para3}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={() => modalShow("Paragraph 3", about[0].para3)}
          >
            Edit
            <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default DashAbout;
