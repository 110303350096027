import * as React from 'react';
import HomeBanner from './HomeBanner';
import { useEffect } from 'react';
import '../Style.css';
import HomeClient from './HomeClient';
import HomeService from './HomeService';
import HomeStrength from './HomeStrength';
import Testimonial from './Testimonial';
import HomeTeam from './HomeTeam';
import Footer from './Footer';

const Home = () => {
  useEffect(() => {
    document.title = "AIIPL | Home";  
  });
  
  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}>
      <HomeBanner />
      <HomeClient />
      <HomeService />
      <HomeStrength />
      <Testimonial />
      <HomeTeam />
      <Footer />
      </div>
    </>
  )
}

export default Home