import * as React from "react";
import {
  Paper,
  Typography,
  Button,
  Alert,
  Box,
  TextField,
} from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import ImageIcon from "@mui/icons-material/Image";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SendIcon from "@mui/icons-material/Send";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { json } from "react-router";

const DashTeam = () => {
  const [team, setTeam] = useState([{}]);
  const [currentMember, setCurrentMember] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [validationErrorVisibility, setValidationErrorVisibility] =
    useState("hidden");
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState("");
  useEffect(() => {
    document.title = "AIIPL | Dashboard Team";
    var url = "https://aiipl.in/app/admin/api/team.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setTeam(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, [team]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtension = [".jpg", ".png"];
      const selectedFileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtension.includes("." + selectedFileExtension)) {
        const maxSize = 1024 * 1024 * 2;
        if (file.size < maxSize) {
          setSelectedFile(file);
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {file.name} is a valid file!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                File size must be less than 2MB!
              </Typography>
            </Alert>
          );
        }
      } else {
        setSelectedFile(null);
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Invalid file extension. Please select a file with .jpg or .png
              extension.
            </Typography>
          </Alert>
        );
      }
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const uploadMember = async (name) => {
    if (selectedFile) {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            File is uploading...
          </Typography>
        </Alert>
      );

      var url = "https://aiipl.in/app/admin/api/changeMemberImageApi.php";
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("fname", firstName);
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Uploaded successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Please select a file before upload!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const uploadNewMember = async () => {
    if (selectedFile) {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            File is uploading...
          </Typography>
        </Alert>
      );

      var url = "https://aiipl.in/app/admin/api/addNewMemberImageApi.php";
      const formData = new FormData();
      formData.append("file", selectedFile);
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            document.getElementById("new_member_image").src = response[0].file;
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Uploaded successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Please select a file before upload!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const setVal = (e, val) => {
    if (val === "setFName") {
      setFirstName(e);
    } else if (val === "setLName") {
      setLastName(e);
    } else if (val === "setDesig") {
      setDesignation(e);
    } else if (val === "setDescr") {
      setDescription(e);
    }
  };

  const changeMemberInfo = () => {
    if (firstName !== "") {
      if (lastName !== "") {
        if (designation !== "") {
          if (description !== "") {
            var url = "https://aiipl.in/app/admin/api/changeMemberInfoApi.php";
            const formData = new FormData();
            formData.append("id", currentMember);
            formData.append("fname", firstName);
            formData.append("lname", lastName);
            formData.append("desig", designation);
            formData.append("descr", description);
            fetch(url, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((response) => {
                if (response[0].result === "success") {
                  setValidationError(
                    <Alert
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      severity="success"
                    >
                      <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                        Updated successfully!
                      </Typography>
                    </Alert>
                  );
                  modalClose();
                } else {
                  setValidationError(
                    <Alert
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      severity="error"
                    >
                      <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                        {response[0].result}
                      </Typography>
                    </Alert>
                  );
                }
              })
              .catch((error) => {
                setValidationError(
                  <Alert
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    severity="error"
                  >
                    <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                      {error.message}
                    </Typography>
                  </Alert>
                );
              });
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Description is blank!
                </Typography>
              </Alert>
            );
          }
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Designation is blank!
              </Typography>
            </Alert>
          );
        }
      } else {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Last name is blank!
            </Typography>
          </Alert>
        );
      }
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            First name is blank!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const addNewMember = () => {
    if (firstName !== "") {
      if (lastName !== "") {
        if (designation !== "") {
          if (description !== "") {
            setCurrentMember();
            var url = "https://aiipl.in/app/admin/api/addNewMemberInfoApi.php";
            const formData = new FormData();
            formData.append("fname", firstName);
            formData.append("lname", lastName);
            formData.append("desig", designation);
            formData.append("descr", description);
            fetch(url, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((response) => {
                if (response[0].result === "success") {
                  setValidationError(
                    <Alert
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      severity="success"
                    >
                      <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                        Added successfully!
                      </Typography>
                    </Alert>
                  );
                  modalClose();
                } else {
                  setValidationError(
                    <Alert
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      severity="error"
                    >
                      <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                        {response[0].result}
                      </Typography>
                    </Alert>
                  );
                }
              })
              .catch((error) => {
                setValidationError(
                  <Alert
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    severity="error"
                  >
                    <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                      {error.message}
                    </Typography>
                  </Alert>
                );
              });
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Description is blank!
                </Typography>
              </Alert>
            );
          }
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Designation is blank!
              </Typography>
            </Alert>
          );
        }
      } else {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Last name is blank!
            </Typography>
          </Alert>
        );
      }
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            First name is blank!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const modalShow = (id, fname, lname, desig, descr, type) => {
    setCurrentMember(id);
    setModalType(type);
    setFirstName(fname);
    setLastName(lname);
    setDesignation(desig);
    setDescription(descr);
    setShow(true);
  };

  const modalClose = () => {
    if (modalType === "new") {
      setSelectedFile(null);
    }
    setShow(false);
  };

  const removeMember = (id) => {
    var sure = window.confirm("Are you sure?");
    if (sure) {
      var url = "https://aiipl.in/app/admin/api/removeMemberApi.php";
      const formData = new FormData();
      formData.append("id", id);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Removed successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Task aborted!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          marginTop: "10rem",
          marginBottom: "10rem",
          zIndex: "9999",
        }}
      >
        <div>
          {modalType === "new" ? (
            <Modal
              show={show}
              style={{ marginTop: "10px", zIndex: 9999 }}
              size="lg"
            >
              <Modal.Header
                className="text-white"
                style={{ background: "#00004d" }}
              >
                <Modal.Title style={{ fontSize: "20px" }}>
                  Add New Member
                </Modal.Title>
                <CloseButton variant="white" onClick={modalClose} />
              </Modal.Header>

              <Modal.Body style={{ background: "transparent" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: ".5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "17rem",
                        height: "17rem",
                        boxShadow: "1px 2px 3px rgba(0,0,0,.4)",
                        borderRadius: "5px",
                        border: "1px solid rgba(0,0,0,.25)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        id="new_member_image"
                        src="https://cdn.iconscout.com/icon/free/png-256/free-upload-675-475051.png"
                        alt=""
                        style={{
                          width: "15rem",
                          height: "15rem",
                          border: "1px solid rgba(0,0,0,.25)",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="file"
                      id="new_member"
                      style={{
                        marginBottom: "1rem",
                      }}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Ubuntu",
                        marginRight: 1,
                        backgroundColor: "#00004d",
                      }}
                      onClick={uploadNewMember}
                    >
                      Add Image
                      <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                    </Button>
                    <TextField
                      id="fname"
                      label="First Name"
                      value={firstName}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: 300,
                        marginTop: 2,
                      }}
                      onChange={(e) => setVal(e.target.value, "setFName")}
                    />
                    <TextField
                      id="lname"
                      label="Last Name"
                      value={lastName}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: 300,
                        marginTop: 2,
                      }}
                      onChange={(e) => setVal(e.target.value, "setLName")}
                    />
                    <TextField
                      id="desig"
                      label="Designation"
                      value={designation}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: 300,
                        marginTop: 2,
                      }}
                      onChange={(e) => setVal(e.target.value, "setDesig")}
                    />
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Description"
                      style={{
                        width: '300px',
                        marginTop: '1rem'
                      }}
                      onChange={(e) => setVal(e.target.value, "setDescr")}
                    />
                  </Box>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                  onClick={addNewMember}
                >
                  Submit
                  <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
                <Button
                  onClick={modalClose}
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                >
                  Close
                  <CancelPresentationIcon
                    sx={{ marginLeft: 1, fontSize: 20 }}
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            <Modal show={show} style={{ marginTop: "80px", zIndex: 9999 }}>
              <Modal.Header
                className="text-white"
                style={{ background: "#00004d" }}
              >
                <Modal.Title style={{ fontSize: "20px" }}>
                  {firstName.toUpperCase()} {lastName.toLocaleUpperCase()}
                </Modal.Title>
                <CloseButton variant="white" onClick={modalClose} />
              </Modal.Header>

              <Modal.Body style={{ background: "transparent" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    id={firstName}
                    label="First Name"
                    value={firstName}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      width: 300,
                      marginTop: 2,
                    }}
                    onChange={(e) => setVal(e.target.value, "setFName")}
                  />
                  <TextField
                    id={lastName}
                    label="Last Name"
                    value={lastName}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      width: 300,
                      marginTop: 2,
                    }}
                    onChange={(e) => setVal(e.target.value, "setLName")}
                  />
                  <TextField
                    id={designation}
                    label="Designation"
                    value={designation}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      width: 300,
                      marginTop: 2,
                    }}
                    onChange={(e) => setVal(e.target.value, "setDesig")}
                  />
                  <Form.Control
                      as="textarea"
                      rows={5}
                      defaultValue={description}
                      style={{
                        width: '300px',
                        marginTop: '1rem'
                      }}
                      onChange={(e) => setVal(e.target.value, "setDescr")}
                    />
                </Box>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                  onClick={changeMemberInfo}
                >
                  Submit
                  <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
                <Button
                  onClick={modalClose}
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                >
                  Close
                  <CancelPresentationIcon
                    sx={{ marginLeft: 1, fontSize: 20 }}
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        <div
          style={{
            zIndex: "999999",
            position: "fixed",
            top: "8.5rem",
            visibility: validationErrorVisibility,
          }}
        >
          {validationError}
        </div>
        <div>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Ubuntu",
              marginRight: 1,
              backgroundColor: "#00004d",
            }}
            onClick={() => modalShow("", "", "", "", "", "new")}
          >
            New Member
            <AddBoxIcon sx={{ marginLeft: 1, fontSize: 20 }} />
          </Button>
        </div>
        {team.map((member) => (
          <Paper
            key={member.fname}
            sx={{
              width: 1200,
              height: 'auto',
              padding: 5,
              boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
              background: "transparent",
              zIndex: 999,
            }}
          >
            <div
              style={{
                padding: "15px",
                background: "rgba(255,255,255,.25)",
                borderRadius: "5px",
                boxShadow: "2px 3px 5px rgba(0,0,0,.4)",
                display: "flex",
                alignItems: "center",
                justifyContent: 'space-between'
              }}
            >
              <div>
                <img
                  src={member.img}
                  style={{
                    width: "350px",
                    height: "350px",
                    borderRadius: "5px",
                  }}
                  alt={member.fname}
                />
              </div>
              <div
                style={{
                  width: "350px",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 14,
                  }}
                >
                  Name: {member.fname} {member.lname}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 14,
                    fontWeight: 100,
                  }}
                >
                  Designation: {member.desig}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 12,
                    fontWeight: 100,
                    width: 460
                  }}
                >
                  Description: {member.descr}
                </Typography>
                <div>
                  <input
                    type="file"
                    id={member.fname}
                    style={{
                      marginBottom: "1rem",
                    }}
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                    onClick={() => uploadMember(member.fname)}
                  >
                    Change Image
                    <ImageIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#00004d",
                  }}
                  onClick={() =>
                    modalShow(member.id, member.fname, member.lname, member.desig, member.descr, "change")
                  }
                >
                  Edit Info
                  <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#262626",
                  }}
                  onClick={() => removeMember(member.id)}
                >
                  Remove Member
                  <DeleteIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
              </div>
            </div>
          </Paper>
        ))}
      </div>
    </>
  );
};

export default DashTeam;
