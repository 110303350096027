import Carousel from "react-bootstrap/Carousel";
import { Modal, CloseButton } from "react-bootstrap";
import { Alert, Box, TextField, Typography, Button } from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SendIcon from "@mui/icons-material/Send";
import GoogleIcon from '@mui/icons-material/Google';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useState, useEffect } from "react";
import "../Style.css";

function HomeBanner() {
  const [refNo, setRefNo] = useState("");
  const [clname, setClname] = useState("");
  const [cperson, setCperson] = useState("");
  const [mob, setMob] = useState("");
  const [email, setEmail] = useState("");
  const [site, setSite] = useState("");
  const [issue, setIssue] = useState("");
  const [trackError, setTrackError] = useState("");
  const [modalType, setModalType] = useState("");
  const [clients, setClients] = useState([{}]);
  const [images, setImages] = useState([{}]);
  const [clog, setClog] = useState([{}]);
  const [clogFound, setClogFound] = useState("no");
  const [show, setShow] = useState(false);

  const downloadFromPlaystore = (url) => {
    window.location.href = url;
  };

  const handleChange = (e) => {
    e.target.value === "Select Client"
      ? setClname("")
      : setClname(e.target.value);
  };

  const modalShow = (type) => {
    setModalType(type);
    setShow(true);
    setTrackError("");
  };
  const setVal = (e, val) => {
    if (val === "setRefNo") {
      setRefNo(e);
    } else if (val === "setClname") {
      setClname(e);
    } else if (val === "setCperson") {
      setCperson(e);
    } else if (val === "setMob") {
      setMob(e);
    } else if (val === "setEmail") {
      setEmail(e);
    } else if (val === "setSite") {
      setSite(e);
    } else if (val === "setIssue") {
      setIssue(e);
    }
    setTrackError("");
  };
  const modalClose = () => {
    setRefNo("");
    setClname("");
    setCperson("");
    setMob("");
    setEmail("");
    setSite("");
    setIssue("");
    setShow(false);
  };
  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/homeBanner.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setImages(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }, []);
  useEffect(() => {
    var url = "https://aiipl.in/app/admin/api/clientData.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setClients(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
  }, []);
  function trackSubmit() {
    if (refNo === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Reference Number has left blank!
          </Typography>
        </Alert>
      );
    } else {
      var url = "https://aiipl.in/app/admin/api/trackcall.php";
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      var Data = {
        rid: refNo,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].name === "Invalid Refercence Number!") {
            setTrackError(
              <Alert sx={{ marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].name}
                </Typography>
              </Alert>
            );
          } else {
            setClogFound("yes");
            setClog(response);
          }
        })
        .catch((error) => {
          setTrackError(
            <Alert sx={{ marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Error: {error}
              </Typography>
            </Alert>
          );
        });
    }
  }
  function logSubmit() {
    if (clname === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Client has left blank!
          </Typography>
        </Alert>
      );
    } else if (cperson === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Contact person has left blank!
          </Typography>
        </Alert>
      );
    } else if (mob === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Mobile Number has left blank!
          </Typography>
        </Alert>
      );
    } else if (email === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Email has left blank!
          </Typography>
        </Alert>
      );
    } else if (site === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Site Address has left blank!
          </Typography>
        </Alert>
      );
    } else if (issue === "") {
      setTrackError(
        <Alert sx={{ marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Issue has left blank!
          </Typography>
        </Alert>
      );
    } else {
      var url = "https://aiipl.in/app/admin/api/callLog.php";
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      var Data = {
        mcaln: clname,
        caln: cperson,
        calm: mob,
        cale: email,
        cals: site,
        calms: issue,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          setTrackError(
            <Alert sx={{ marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {response[0].result}
              </Typography>
            </Alert>
          );
        })
        .catch((error) => {
          setTrackError(
            <Alert sx={{ marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Error: {error}
              </Typography>
            </Alert>
          );
        });
    }
  }
  return (
    <>
      {modalType === "Track Call" ? (
        <div style={{ width: '80vw' }}>
          <Modal
            show={show}
            style={{ marginTop: "130px", zIndex: '9999', maxWidth: '100vw' }}
            size="sm"
          >
            <Modal.Header
              className="text-white"
              style={{ background: "#00004d" }}
            >
              <Modal.Title style={{ fontSize: "20px" }}>
                {modalType}
              </Modal.Title>
              <CloseButton variant="white" onClick={modalClose} />
            </Modal.Header>

            <Modal.Body>
              {trackError}
              {clogFound === "yes"
                ? clog.map((cl, index) => (
                    <Box key={index}>
                      <Alert sx={{ marginBottom: 2 }} severity="success">
                        <Typography sx={{ fontFamily: "Ubuntu", fontSize: 14 }}>
                          Name: {cl.name}
                        </Typography>
                      </Alert>
                      <Alert sx={{ marginBottom: 2 }} severity="success">
                        <Typography sx={{ fontFamily: "Ubuntu", fontSize: 14 }}>
                          Site: {cl.site}
                        </Typography>
                      </Alert>
                      <Alert sx={{ marginBottom: 2 }} severity="success">
                        <Typography sx={{ fontFamily: "Ubuntu", fontSize: 14 }}>
                          Status: {cl.status}
                        </Typography>
                      </Alert>
                      <Alert sx={{ marginBottom: 2 }} severity="success">
                        <Typography sx={{ fontFamily: "Ubuntu", fontSize: 14 }}>
                          Log Date: {cl.logd}
                        </Typography>
                      </Alert>
                      <Alert sx={{ marginBottom: 2 }} severity="success">
                        <Typography sx={{ fontFamily: "Ubuntu", fontSize: 14 }}>
                          Last Update Date: {cl.lastd}
                        </Typography>
                      </Alert>
                    </Box>
                  ))
                : ""}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  id="refno"
                  label="Reference Number"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  sx={{
                    width: 300,
                  }}
                  onChange={(e) => setVal(e.target.value, "setRefNo")}
                />
              </Box>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={trackSubmit}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Submit
                <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
              <Button
                onClick={modalClose}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Close
                <CancelPresentationIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div>
          <Modal show={show} style={{ marginTop: "80px", zIndex: '9999', maxWidth: '100vw' }}>
            <Modal.Header
              className="text-white"
              style={{ background: "#00004d" }}
            >
              <Modal.Title style={{ fontSize: "20px" }}>
                {modalType}
              </Modal.Title>
              <CloseButton variant="white" onClick={modalClose} />
            </Modal.Header>

            <Modal.Body>
              {trackError}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <select onChange={handleChange} style={{ width: "250px" }}>
                  <option>Select Client</option>
                  {clients.map((client, index) => (
                    <option key={index}>{client.label}</option>
                  ))}
                </select>
                <TextField
                  id="clname"
                  label="Client Name"
                  variant="outlined"
                  value={clname}
                  size="small"
                  autoComplete="off"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setClname")}
                />
                <TextField
                  id="cperson"
                  label="Contact Person"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setCperson")}
                />
                <TextField
                  id="mob"
                  label="Mobile Number"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setMob")}
                />
                <TextField
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setEmail")}
                />
                <TextField
                  id="site"
                  label="Site Address"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  multiline="true"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setSite")}
                />
                <TextField
                  id="issue"
                  label="Issue"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  style={{}}
                  sx={{
                    fontFamily: "Ubuntu",
                    marginBottom: 1,
                    width: 250,
                  }}
                  onChange={(e) => setVal(e.target.value, "setIssue")}
                />
              </Box>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={logSubmit}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Submit
                <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
              <Button
                onClick={modalClose}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Close
                <CancelPresentationIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <Carousel style={{ zIndex: "9999", maxWidth: "100vw" }}>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 carousel-item"
              src={image.url}
              alt="First slide"
            />
            <Carousel.Caption className="carousel-caption">
              <div>
                <h3>{image.title}</h3>
                <p>{image.content}.</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="playstore_div" style={{ zIndex: '9999' }}>
        <p className="playstore">
          We are also on google play store !
        </p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#d62516",
            marginBottom: "1rem"
          }}
          onClick={() => downloadFromPlaystore("https://play.google.com/store/apps/details?id=com.debjitgho.aiipl&pli=1")}
        >
          <GoogleIcon sx={{ marginRight: 1, fontSize: 12 }} />
          DOWNLOAD NOW
        </Button>
      </div>
      <div className="call-buttons">
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontFamily: "Ubuntu",
            marginLeft: 2,
            marginRight: 2,
            backgroundColor: "#00004d",
          }}
          onClick={() => modalShow("Track Call")}
        >
          Track Call
          <GpsFixedIcon sx={{ marginLeft: 1, fontSize: 20 }} />
        </Button>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            marginLeft: 2,
            marginRight: 2,
            backgroundColor: "#00004d",
          }}
          onClick={() => modalShow("Call Log")}
        >
          Call Log
          <NoteAddIcon sx={{ marginLeft: 1, fontSize: 20 }} />
        </Button>
      </div>
    </>
  );
}

export default HomeBanner;
