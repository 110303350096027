import { Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import CallIcon from "@mui/icons-material/Call";
import { useEffect, useState } from "react";
import "../Style.css";
import Footer from "./Footer";

function Service() {
  const [services, setServices] = useState([{}]);
  useEffect(() => {
    document.title = "AIIPL | Service";
    var url = "https://aiipl.in/app/admin/api/service.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setServices(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
      //eslint-disable-next-line
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };
  
  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>OUR SERVICES</h1>
          </div>
        </div>
      </div>
      <div className="about_div" style={{ height: 'auto' }}>
        <div style={{ marginTop: "3rem", marginLeft: "3rem" }}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 10,
              }}
            >
              {services.map((service, index) => (
                <Paper
                  style={{
                    background: "rgba(255,255,255,.5)",
                    padding: "1rem",
                    width: "20rem",
                    height: "22rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  elevation={10}
                >
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 22,
                      textAlign: "center",
                    }}
                  >
                    <BakeryDiningIcon sx={{ marginLeft: 1.5, fontSize: 55 }} />
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 900,
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    {service.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 15,
                      textAlign: "center",
                      height: 75,
                      marginBottom: 5,
                    }}
                  >
                    {service.info}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Ubuntu",
                      marginRight: 1,
                      backgroundColor: "#00004d",
                    }}
                    onClick={() => callTollFree("tel:18002583939")}
                  >
                    <CallIcon sx={{ marginRight: 1, fontSize: 20 }} />
                    REQUEST QUOTE
                  </Button>
                </Paper>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
      <div className="request_call">
        <p className="quote">
          Want to request a quote now !
        </p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Service;
