import * as React from "react";
import { Button } from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import Footer from "./Footer";

const Gallery = () => {
  const [images, setImages] = useState([{}]);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  useEffect(() => {
    document.title = "AIIPL | Gallery";
    var url = "https://aiipl.in/app/admin/api/gallery.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setImages(response);
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
    //eslint-disable-next-line
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };

  const modalShow = (img) => {
    setImage(img);
    setShow(true);
  };

  const modalClose = () => {
    setImage("");
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        style={{ maxWidth: "100vw", zIndex: "99999", marginTop: "0" }}
        size="xl"
      >
        <Modal.Header>
          <CloseButton variant="#00004d" onClick={modalClose} />
        </Modal.Header>
        <Modal.Body>
          <img
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid rgba(0,0,0,.5)",
              borderRadius: '5px'
            }}
            src={image}
            alt=""
          />
        </Modal.Body>
      </Modal>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>OUR GALLERY</h1>
          </div>
        </div>
      </div>
      <div className="gallery_div">
        {images.map((img, index) => (
          <img
            className="gallery_img"
            src={img.url}
            alt=""
            onClick={() => modalShow(img.url)}
          />
        ))}
      </div>
      <div className="request_call">
        <p className="quote">Want to request a quote now !</p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Gallery;
