import * as React from "react";
import { Paper, Typography, Button, Alert, TextField, Grid, Box } from "@mui/material";
import { Modal, CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SendIcon from "@mui/icons-material/Send";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";

const DashService = () => {
  const [service, setService] = useState([{}]);
  const [validationError, setValidationError] = useState(null);
  const [currentService, setCurrentService] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceInfo, setServiceInfo] = useState("");
  const [validationErrorVisibility, setValidationErrorVisibility] =
    useState("hidden");
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState("");

  const setVal = (e, type) => {
    if (e !== "" || e !== null) {
      if (type === "setName") {
        setServiceName(e);
      } else if (type === "setInfo") {
        setServiceInfo(e);
      }
    } else {
      if (type === "setName") {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Service name is blank!
            </Typography>
          </Alert>
        );
      } else if (type === "setInfo") {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Service info is blank!
            </Typography>
          </Alert>
        );
      }
      setValidationErrorVisibility("visible");
      setTimeout(function () {
        setValidationErrorVisibility("hidden");
      }, 5000);
    }
  };

  const updateService = () => {
    var url = "https://aiipl.in/app/admin/api/service.php";
    const formData = new FormData();
    formData.append("id", currentService);
    formData.append("name", serviceName);
    formData.append("info", serviceInfo);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response[0].result === "success") {
          modalClose();
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Updated successfully!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {response[0].result}
              </Typography>
            </Alert>
          );
        }
      })
      .catch((error) => {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {error.message}
            </Typography>
          </Alert>
        );
      });
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  const addNewService = () => {
    var url = "https://aiipl.in/app/admin/api/addNewServiceApi.php";
    const formData = new FormData();
    formData.append("name", serviceName);
    formData.append("info", serviceInfo);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response[0].result === "success") {
          modalClose();
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                Added successfully!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {response[0].result}
              </Typography>
            </Alert>
          );
        }
      })
      .catch((error) => {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {error.message}
            </Typography>
          </Alert>
        );
      });
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  useEffect(() => {
    document.title = "AIIPL | Dashboard Service";
    var url = "https://aiipl.in/app/admin/api/service.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setService(response);
      })
      .catch((error) => {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              {error.message}
            </Typography>
          </Alert>
        );
      });
    //eslint-disable-next-line
  }, [service]);

  const modalShow = (id, name, info, type) => {
    setModalType(type);
    setCurrentService(id);
    setServiceName(name);
    setServiceInfo(info);
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const removeService = (id) => {
    var sure = window.confirm("Are you sure?");
    if (sure) {
      var url = "https://aiipl.in/app/admin/api/removeServiceApi.php";
      const formData = new FormData();
      formData.append("id", id);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response[0].result === "success") {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  Removed successfully!
                </Typography>
              </Alert>
            );
          } else {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {response[0].result}
                </Typography>
              </Alert>
            );
          }
        })
        .catch((error) => {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {error.message}
              </Typography>
            </Alert>
          );
        });
    } else {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Task aborted!
          </Typography>
        </Alert>
      );
    }
    setValidationErrorVisibility("visible");
    setTimeout(function () {
      setValidationErrorVisibility("hidden");
    }, 5000);
  };

  return (
    <>
      <div>
        {modalType === "new" ? (
          <Modal show={show} style={{ marginTop: "80px", zIndex: 9999 }}>
            <Modal.Header
              className="text-white"
              style={{ background: "#00004d" }}
            >
              <Modal.Title style={{ fontSize: "20px" }}>
                Add New Service
              </Modal.Title>
              <CloseButton variant="white" onClick={modalClose} />
            </Modal.Header>

            <Modal.Body style={{ background: "transparent" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
              <TextField
                    id="name"
                    label="Service Name"
                    value={serviceName}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2
                    }}
                    style={{
                      width: "300px",
                    }}
                    onChange={(e) => setVal(e.target.value, "setName")}
                  />
                  <Form.Control
                    as="textarea"
                    rows={5}
                    defaultValue={serviceInfo}
                    placeholder="Service Info"
                    style={{
                      width: "300px",
                    }}
                    onChange={(e) => setVal(e.target.value, "setInfo")}
                  />
              </div>                
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
                onClick={addNewService}
              >
                Submit
                <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
              <Button
                onClick={modalClose}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Close
                <CancelPresentationIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={show} style={{ marginTop: "80px", zIndex: 9999 }}>
            <Modal.Header
              className="text-white"
              style={{ background: "#00004d" }}
            >
              <Modal.Title style={{ fontSize: "20px" }}>
                {serviceName}
              </Modal.Title>
              <CloseButton variant="white" onClick={modalClose} />
            </Modal.Header>

            <Modal.Body style={{ background: "transparent" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="name"
                  label="Service Name"
                  value={serviceName}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                  style={{
                    width: "300px",
                  }}
                  onChange={(e) => setVal(e.target.value, "setName")}
                />
                <Form.Control
                  as="textarea"
                  rows={5}
                  defaultValue={serviceInfo}
                  placeholder="Service Info"
                  style={{
                    width: "300px",
                  }}
                  onChange={(e) => setVal(e.target.value, "setInfo")}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
                onClick={updateService}
              >
                Submit
                <SendIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
              <Button
                onClick={modalClose}
                variant="contained"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: 1,
                  backgroundColor: "#00004d",
                }}
              >
                Close
                <CancelPresentationIcon sx={{ marginLeft: 1, fontSize: 20 }} />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <div
        style={{
          zIndex: "999999",
          position: "fixed",
          top: "6.5rem",
          left: "35rem",
          visibility: validationErrorVisibility,
        }}
      >
        {validationError}
      </div>
      <div
        style={{
          position: "relative",
          top: "10rem",
          left: "38rem",
          marginBottom: "12rem",
        }}
      >
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => modalShow("", "", "", "new")}
        >
          New Service
          <AddBoxIcon sx={{ marginLeft: 1, fontSize: 20 }} />
        </Button>
      </div>
      <div style={{
        margin: "3rem",
        marginTop: "10rem",
        marginBottom: "10rem",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: "rgba(255,255,255,.25)",
        borderRadius: "5px",
        padding: "5.5rem",
        paddingTop: "6rem",
        paddingLeft: "10rem",
        backdropFilter: "blur(5px)",
      }}>
      <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 6.5,
              }}
            >
        {service.map((serv, index) => (
          <Paper
            key={index}
            sx={{
              padding: 2.5,
              marginBottom: 2.5,
              width: 325,
              boxShadow: "rgba(0,0,0,.35) 2px 2px 10px",
              background: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: 1.5,
              zIndex: "99999",
            }}
          >
            <Typography
              style={{
                fontFamily: "Ubuntu",
                fontSize: "15px",
                fontWeight: "900",
              }}
            >
              Name: {serv.name}
            </Typography>
            <Typography
              style={{
                fontFamily: "Ubuntu",
                fontSize: "14px",
              }}
            >
              Info: {serv.info}
            </Typography>
            <div style={{
              display: 'flex',
              gap: '1rem'
            }}>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                marginRight: 1,
                backgroundColor: "#00004d",
              }}
              onClick={() => modalShow(serv.id, serv.name, serv.info, "")}
            >
              Edit
              <UpdateIcon sx={{ marginLeft: 1, fontSize: 20 }} />
            </Button>
            <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: 1,
                    backgroundColor: "#262626",
                  }}
                  onClick={() => removeService(serv.id)}
                >
                  Remove
                  <DeleteIcon sx={{ marginLeft: 1, fontSize: 20 }} />
                </Button>
            </div>
          </Paper>
        ))}
      </Box>
      </Grid>
      </div>
    </>
  );
};

export default DashService;
