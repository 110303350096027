import * as React from 'react';
import { AppBar, Toolbar, Container, Box, Button, Typography, IconButton } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CottageIcon from '@mui/icons-material/Cottage';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../Style.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [siteInfo, setSiteInfo] = useState([{}]);
  const [show, setShow] = useState('viewD');
  const handleShow = () => {
    setShow(show === 'viewD' ? 'viewM' : 'viewD');
  }
  useEffect(() => {
    document.body.style.background = "rgba(2,0,36)";
    document.body.style.background = "linear-gradient(90deg, rgba(106, 169, 121) 0%, rgba(76, 76, 199) 35%, rgba(0,212,255,1) 100%)";
    var url = 'https://aiipl.in/app/admin/api/siteInfo.php';
    fetch(url).then((response) => response.json()).then((response) => {
      setSiteInfo(response);
    }
    ).catch((error) => {
      alert('Error: ' + error);
    }
    )
    //eslint-disable-next-line
  }, []);

  function logoutSubmit() {
    localStorage.setItem('login', '');
    localStorage.setItem('loginStatus', 'Logged out successfully!');
    navigate("/login");
  }

  return (
    <>
      <section className='loginSection'>
        <div className='circle1'></div>
        <div className='circle2'></div>
        <AppBar
          sx={{ height: 80, marginTop: 5, display: 'flex', justifyContent: 'center', background: 'rgba(255,255,255,.5)' }}
          position='fixed'
          className='AppBar'
        >
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              <img src={require('../assets/logo/logo.png')} alt='Logo' className='nav-logo' />
              <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 10 }}>
                {
                  siteInfo.map((info, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
                      <Typography
                        variant='h6'
                        style={{ color: '#000' }}
                        sx={{ fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 900 }}
                      >
                        {info.title}
                      </Typography>
                      <Typography
                        variant='h6'
                        style={{ color: '#000' }}
                        sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}
                      >
                        {info.content}
                      </Typography>
                    </Box>
                  ))
                }
                <Box className={show}>
                  <Button
                    className='button'
                    component={NavLink}
                    end
                    to='/dashboard'
                    color='inherit'
                    onClick={handleShow}
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? ' #00004d' : '#000' } }}
                  >
                    <CottageIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Home
                  </Button>
                  <Button
                    className='button'
                    component={NavLink}
                    to='/dashboard/about'
                    color='inherit'
                    onClick={handleShow}
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <ArticleIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />About
                  </Button>
                  <Button
                    className='button'
                    component={NavLink}
                    to='/dashboard/service'
                    color='inherit'
                    onClick={handleShow}
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <SettingsIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Service
                  </Button>
                  <Button
                    className='button'
                    onClick={handleShow}
                    component={NavLink}
                    to='/dashboard/division'
                    color='inherit'
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <CollectionsBookmarkIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Division
                  </Button>
                  <Button
                    className='button'
                    onClick={handleShow}
                    component={NavLink}
                    to='/dashboard/client'
                    color='inherit'
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <BusinessCenterIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Client
                  </Button>
                  <Button
                    className='button'
                    onClick={handleShow}
                    component={NavLink}
                    to='/dashboard/career'
                    color='inherit'
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <SchoolIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Career
                  </Button>
                  <Button
                    className='button'
                    onClick={handleShow}
                    component={NavLink}
                    to='/dashboard/contact'
                    color='inherit'
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <EmailIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    />Contact
                  </Button>
                  <Button
                    className='button'
                    component={NavLink}
                    to='/login'
                    onClick={logoutSubmit}
                    color='inherit'
                    sx={{ fontWeight: 100, marginRight: 1, fontFamily: 'Ubuntu' }}
                    style={({ isActive }) => { return { color: isActive ? '#00004d' : '#000' } }}
                  >
                    <LogoutIcon
                      sx={{ marginRight: .5, fontSize: 18 }}
                    /> Logout
                  </Button>
                </Box>
                <Box className='resp' onClick={handleShow}>
                  <IconButton color='inherit'>
                    <DehazeIcon />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Outlet />
      </section>
    </>
  )
}

export default Dashboard