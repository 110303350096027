import * as React from 'react';
import Layout from './component/Layout';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Home from './component/Home';
import About from './component/About';
import Service from './component/Service';
import Gallery from './component/Gallery';
import Contact from './component/Contact';
import Client from './component/Client';
import Career from './component/Career';
import NotFound from './component/NotFound';
import Login from './component/Login';
import Dashboard from './component/Dashboard';
import Protected from './component/Protected';
import DashHome from './component/DashHome';
import DashAbout from './component/DashAbout';
import DashService from './component/DashService';
import DashGallery from './component/DashGallery';
import DashClient from './component/DashClient';
import DashCareer from './component/DashCareer';
import DashContact from './component/DashContact';
import DashBanner from './component/DashBanner';
import Division from './component/Division';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsOfUse from './component/TermsOfUse';
import DashTeam from './component/DashTeam';
import DashDivision from './component/DashDivision';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='about' element={<About />} />
              <Route path='service' element={<Service />} />
              <Route path='division' element={<Division />} />
              <Route path='gallery' element={<Gallery />} />
              <Route path='client' element={<Client />} />
              <Route path='career' element={<Career />} />
              <Route path='contact' element={<Contact />} />
              <Route path='privacypolicy' element={<PrivacyPolicy />} />
              <Route path='termsofuse' element={<TermsOfUse />} />
              <Route path='*' element={<NotFound />} />
            </Route>
            <Route path='login' element={<Login />} />
            <Route element={
              <>
                  <Protected Component={Dashboard} />
              </>
            }>
              <Route path='/dashboard' element={<Protected Component={DashHome} />} />
              <Route path='/dashboard/about' element={<Protected Component={DashAbout} />} />
              <Route path='/dashboard/service' element={<Protected Component={DashService} />} />
              <Route path='/dashboard/gallery' element={<Protected Component={DashGallery} />} />
              <Route path='/dashboard/division' element={<Protected Component={DashDivision} />} />
              <Route path='/dashboard/client' element={<Protected Component={DashClient} />} />
              <Route path='/dashboard/career' element={<Protected Component={DashCareer} />} />
              <Route path='/dashboard/contact' element={<Protected Component={DashContact} />} />
              <Route path='/dashboard/banner' element={<Protected Component={DashBanner} />} />
              <Route path='/dashboard/team' element={<Protected Component={DashTeam} />} />
            </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
