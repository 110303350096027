import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { Row, Col, Container } from 'react-bootstrap';
import { useEffect } from 'react';
import '../Style.css';

const DashClient = () => {
    useEffect(() => {
        document.title = "AIIPL | Dashboard Client";
    });

    return (
        <>
            <Container className='center'>
                <Row>
                    <Col>
                        <Paper
                            sx={{
                                padding: 10,
                                boxShadow: 'rgba(0,0,0,.35) 2px 2px 10px',
                                background: 'transparent'
                            }}
                        >
                            <Typography variant='h6'>Dashboard Home Page</Typography>
                        </Paper>
                    </Col>
                    <Col>
                        <Paper
                            sx={{
                                padding: 10,
                                boxShadow: 'rgba(0,0,0,.35) 2px 2px 10px',
                                background: 'transparent'
                            }}
                        >
                            <Typography variant='h6'>Dashboard Content</Typography>
                        </Paper>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DashClient