import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const NotFound = () => {
  const [path, setPath] = useState('');
  const [msg, setMsg] = useState('');
  const openPath = () => {
    if(window.location.href.includes("www.")){
      setPath('https://' + window.location.pathname.substring(1));
      setMsg('Redirecting...')
    }
    else{
      setMsg('Page Not Found!');
    }
  }
  useEffect(() => {
    openPath();
    document.title = "AIIPL | " + msg; 
    if(msg != 'Page Not Found!'){
      window.location.href = path;
    }
  }, [path]);

  return (
    <>
        <Paper 
            sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%',
                transform: 'translate(-50%,-50%)',
                padding: 10,
                boxShadow: 'rgba(0,0,0,.35) 2px 2px 10px',
                background: 'transparent',
            }}
        >
            <Typography variant='h3'>{msg}</Typography>
        </Paper>
    </>
  )
}

export default NotFound