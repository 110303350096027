import { Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import CallIcon from "@mui/icons-material/Call";
import { useEffect, useState } from "react";
import "../Style.css";
import Footer from "./Footer";

function Division() {
  const [divisions, setDivisions] = useState([{}]);
  useEffect(() => {
    document.title = "AIIPL | Division";
    var url = "https://aiipl.in/app/admin/api/divisionData.php";
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setDivisions(response);
      })
      .catch((error) => {
        alert("Error: " + error);
      });
    //eslint-disable-next-line
  }, []);

  const callTollFree = (num) => {
    window.location.href = num;
  };

  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>OUR DIVISIONS</h1>
          </div>
        </div>
      </div>
      <div
        className="about_div">
        <div style={{ marginTop: "3rem", marginLeft: "3rem" }}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: "transparent",
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 1fr" },
                gap: 10,
              }}
            >
              {divisions.map((division, index) => (
                <Paper
                  key={index}
                  style={{
                    background: "rgba(255,255,255,.5)",
                    padding: "1rem",
                    width: "18rem",
                    height: "18rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "1.5rem",
                    cursor: "pointer",
                  }}
                  elevation={10}
                  onClick={() => callTollFree(division.site)}
                >
                  <img
                    className="division_img"
                    src={division.img}
                    alt={division.name}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 12,
                      fontWeight: 900,
                      textAlign: "center",
                      marginBottom: 5,
                      padding: 1,
                      background: "rgba(255,255,255,.5)",
                      backdropFilter: "blur(10px)",
                      textTransform: "uppercase",
                      boxShadow: "1px 1px 1px rgba(0,0,0,.25)",
                    }}
                  >
                    {division.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 15,
                      textAlign: "center",
                      height: 75,
                      marginBottom: 5,
                    }}
                  >
                    {division.info}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Grid>
        </div>
      </div>
      <div className="request_call">
        <p className="quote">Want to request a quote now !</p>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 12,
            marginRight: 1,
            backgroundColor: "#00004d",
          }}
          onClick={() => callTollFree("tel:18002583939")}
        >
          <CallIcon sx={{ marginRight: 1, fontSize: 12 }} />
          CALL NOW
        </Button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Division;
