import * as React from "react";
import { Typography, TextField, Button, Alert } from "@mui/material";
import Form from "react-bootstrap/Form";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import Footer from "./Footer";

const Career = () => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [application, setApplication] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [visibility, setVisibility] = useState("hidden");

  useEffect(() => {
    document.title = "AIIPL | Career";
    //eslint-disable-next-line
  }, []);

  const setVal = (e, val) => {
    if (val === "setFName") {
      setFName(e);
    } else if (val === "setLName") {
      setLName(e);
    } else if (val === "setMobile") {
      setMobile(e);
    } else if (val === "setEmail") {
      setEmail(e);
    } else if (val === "setApplication") {
      setApplication(e);
    }
  };

  const openFile = () => {
    document.getElementById("resume").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtension = [".doc", ".docx", ".pdf"];
      const selectedFileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtension.includes("." + selectedFileExtension)) {
        const maxSize = 1024 * 1024 * 2;
        if (file.size < maxSize) {
          setSelectedFile(file);
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="success">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                {file.name} is a valid file!
              </Typography>
            </Alert>
          );
        } else {
          setValidationError(
            <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                File size must be less than 2MB!
              </Typography>
            </Alert>
          );
        }
      } else {
        setSelectedFile(null);
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Invalid file extension. Please select a file with .doc or .docx or
              .pdf extension.
            </Typography>
          </Alert>
        );
      }
    }
    setVisibility("visible");
    setTimeout(function () {
      setVisibility("hidden");
    }, 5000);
  };

  const handleSubmit = async () => {
    if (fname === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            First name is blank!
          </Typography>
        </Alert>
      );
    } else if (lname === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Last name is blank!
          </Typography>
        </Alert>
      );
    } else if (mobile === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Mobile is blank!
          </Typography>
        </Alert>
      );
    } else if (email === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Email is blank!
          </Typography>
        </Alert>
      );
    } else if (application === "") {
      setValidationError(
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
          <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
            Application is blank!
          </Typography>
        </Alert>
      );
    } else {
      if (!selectedFile) {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              Please select a resume to upload!
            </Typography>
          </Alert>
        );
      } else {
        setValidationError(
          <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
            <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
              File is uploading...
            </Typography>
          </Alert>
        );
        var url = "https://aiipl.in/app/admin/api/newCareer.php";
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("mob", mobile);
        formData.append("email", email);
        formData.append("msg", application);
        await fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response[0].result === "success") {
              setValidationError(
                <Alert
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  severity="success"
                >
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    Application has sent successfully!
                  </Typography>
                </Alert>
              );
            } else {
              setValidationError(
                <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                    {response[0].result}
                  </Typography>
                </Alert>
              );
            }
          })
          .catch((error) => {
            setValidationError(
              <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
                <Typography sx={{ fontFamily: "Ubuntu", fontSize: 12 }}>
                  {error.message}
                </Typography>
              </Alert>
            );
          });
      }
    }
    setVisibility("visible");
    setTimeout(function () {
      setVisibility("hidden");
    }, 5000);
  };

  return (
    <>
      <div className="gradient_background">
        <div className="content">
          <div>
            <h1>CAREER WITH US</h1>
          </div>
        </div>
      </div>
      <div className="career_div">
        <div
          style={{
            zIndex: "999999",
            position: "fixed",
            top: "8.5rem",
            visibility: visibility,
            zIndex: "999",
          }}
        >
          {validationError}
        </div>
        <div className="career_content_div">
          <div className="career_content">
            <TextField
              id="fname"
              label="First Name"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setFName")}
            />
            <TextField
              id="lname"
              label="Last Name"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setLName")}
            />
            <TextField
              id="mobile"
              label="Mobile"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
              }}
              onChange={(e) => setVal(e.target.value, "setMobile")}
            />
            <input
              type="file"
              name="resume"
              id="resume"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                marginRight: 1,
                backgroundColor: "#00004d",
              }}
              onClick={openFile}
            >
              <FileCopyIcon sx={{ marginRight: 1, fontSize: 20 }} />
              SELECT RESUME
            </Button>
          </div>
          <div className="career_content">
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              size="small"
              autoComplete="off"
              sx={{
                width: 250,
                marginBottom: 2.5,
              }}
              onChange={(e) => setVal(e.target.value, "setEmail")}
            />
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea2"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Application"
                  onChange={(e) => setVal(e.target.value, "setApplication")}
                  style={{
                    width: "250px",
                    background: "transparent",
                    border: "1px solid rgba(0,0,0,.25)",
                  }}
                />
              </Form.Group>
            </Form>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Ubuntu",
                backgroundColor: "#00004d",
                marginRight: 0.5,
                marginTop: 2.5,
              }}
              onClick={handleSubmit}
            >
              <SendIcon sx={{ marginRight: 1, fontSize: 20 }} /> SUBMIT
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Career;
