import { Alert, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoginIcon from '@mui/icons-material/Login';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Style.css';

function Login() {  
    const pageLoad = () => {
        let login = localStorage.getItem('login');
        let loginStatus = localStorage.getItem('loginStatus');
        if (login) {
            navigate("/dashboard");
        }
        if(loginStatus){
            setTrackError(<Alert sx={{ marginBottom: 2 }} severity='info'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>{loginStatus}</Typography></Alert>);
            setTimeout(function(){
                localStorage.clear();
                window.location.reload();
            }, 10000);
        }
    }                                                                                                               
    useEffect(() => {
        document.title = "AIIPL | Login";
        pageLoad();
        //eslint-disable-next-line
    }, []);
    const navigate = useNavigate();
    const [showhide, setShowhide] = useState('password');
    const [trackError, setTrackError] = useState('');
    const [uname, setUname] = useState('');
    const [pwd, setPwd] = useState('');
    const passwordHandler = () => {
        setShowhide(showhide === 'password' ? 'text' : 'password');
    }
    const setVal = (e, val) => {
        if (val === 'setUname') {
            setUname(e);
        }
        else if (val === 'setPwd') {
            setPwd(e);
        }
        setTrackError('');
    }
    function loginSubmit() {
        if (uname === '') {
            setTrackError(<Alert sx={{ marginBottom: 2 }} severity='error'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>User Name has left blank!</Typography></Alert>);
        }
        else if (pwd === '') {
            setTrackError(<Alert sx={{ marginBottom: 2 }} severity='error'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>Password has left blank!</Typography></Alert>);
        }
        else {
            var url = 'https://aiipl.in/app/admin/api/checkAdmin.php';
            var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            var Data = {
                uname: uname,
                pass: pwd
            };
            fetch(url,
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(Data)
                }
            ).then((response) => response.json()).then((response) => {
                if (response[0].result !== "success") {
                    setTrackError(<Alert sx={{ marginBottom: 2 }} severity='error'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>{response[0].result}</Typography></Alert>);
                    console.log('ok');
                }
                else {
                    setTrackError(<Alert sx={{ marginBottom: 2 }} severity='success'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>Login successful! Redirecting...</Typography></Alert>);
                    setTimeout(function () {
                        localStorage.setItem('login', true);
                        navigate("/dashboard");
                    }, 5000);
                }
            }
            ).catch((error) => {
                setTrackError(<Alert sx={{ marginBottom: 2 }} severity='error'><Typography sx={{ fontFamily: 'Ubuntu', fontSize: 12 }}>Error: {error}</Typography></Alert>);
            }
            )
        }
    }
    const onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          loginSubmit();
        }
    };
    return (
        <>
            <section className='loginSection'>
                <div className='circle1'></div>
                <div className='circle2'></div>
                <Box
                    className='loginBox'
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        padding: 5,
                        background: 'rgba(255,255,255,.25)',
                        boxShadow: 'rgba(0,0,0,.55) 2px 2px 10px'
                    }}
                    style={{
                        flex: 1
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: 5
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt="Avatar"
                            src="https://aiipl.in/images/logo/ARCHISMAN.png"
                            style={{
                                width: '200px',
                                height: '50px',
                                borderRadius: '5px',
                                boxShadow: '1px 2px 3px rgba(0,0,0,.25)'
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginBottom: 3
                        }}
                    >
                        {trackError}
                        <TextField
                            id="uname"
                            label="User Name"
                            variant="outlined"
                            autoComplete="off"
                            sx={{
                                width: 300,
                                fontFamily: 'Ubuntu'
                            }}
                            onChange={(e) => setVal(e.target.value, 'setUname')}
                            onKeyDown={onKeyDownHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginBottom: 3
                        }}
                    >
                        <TextField
                            id="pass"
                            label="Password"
                            type={showhide}
                            variant="outlined"
                            autoComplete="off"
                            sx={{
                                width: 300,
                                fontFamily: 'Ubuntu',
                            }}
                            onChange={(e) => setVal(e.target.value, 'setPwd')}
                            onKeyDown={onKeyDownHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" onClick={passwordHandler}>
                                        {
                                            showhide === 'password' ?
                                                <VisibilityIcon sx={{ cursor: 'pointer' }} /> :
                                                <VisibilityOffIcon sx={{ cursor: 'pointer' }} />
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginBottom: 3
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: '#00004d',
                                fontFamily: 'Ubuntu',
                            }}
                            variant="contained"
                            onClick={loginSubmit}
                        >
                            Login
                            <LoginIcon
                                sx={{ marginLeft: 1, fontSize: 20 }}
                            />
                        </Button>
                    </Box>
                </Box>
            </section>
        </>
    );
}

export default Login;